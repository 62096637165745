import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useSpring, animated } from "react-spring";
import { Link } from "react-router-dom";
import Frame from "components/Frame";
import "./index.scss";
import spotplaceholder from "spotplaceholder.jpg";
import { ReactComponent as Play } from "play.svg";
import themes from "themes.js";
import { Waypoint } from "react-waypoint";
import SlideIn from "components/SlideIn";
import ScrollTrigger from 'components/ScrollTrigger';
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';
import ScrollDown from 'components/ScrollDown';
import { setTheme } from "redux/actions"
import Share from 'components/Share';


const mapStateToProps = state => ({
  theme: themes[state.style.theme]
})
const mapDispatchToProps = dispatch => ({
  setTheme: value => {
    dispatch(setTheme(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(({ theme, setTheme, play, setPlay }) => {

  useEffect(() => {
    setTheme('home')
  },[])

  return (
    <Frame
      className="Home"
      style={theme.content}>
      <div className="Home-facts">
        <ScrollToTopOnMount />
        <Fact body={<>
          Every day <Highlight theme={theme}>2-3 women die</Highlight> in the US from  pregnancy related complications.
        </>} />
        <Fact body={<>In the past 30 years, the number of women dying from these complications has <Highlight theme={theme}>more than doubled.</Highlight></>} />
        <Fact body={<>Black women are at the <Highlight theme={theme}>greatest risk.</Highlight></>} />
        <ScrollTrigger
          onDown={() => {
            setTheme('hope')
          }}
          onUp={() => {
            setTheme('home')
          }} />
        <Fact body={<>But, over <Highlight theme={themes.hope}>half</Highlight> of these deaths are preventable.</>}>
        </Fact>
        <Fact
          body={<>Watch “Save Our Moms” by Lena Waithe and Minhal Baig</>}>
          <YoutubeEmbed
            play={play}
            setPlay={setPlay}
            placeholder={spotplaceholder} />
          <Share text="Approx 700-900 women die a year in the US due to pregnancy-related complications. Over half of these deaths are preventable. You have the power to change that story. Check out this video about one mother’s story." />
        </Fact>
        <Fact body={"We all have the power to change this.  Not sure what to say to the mom in your life? "}>
          <div className="Home-cta">
            <Link
              className="Home-cta-link Home-cta-link--primary"
              to="/chat">
                <div className="Home-cta-link-heading">Start the conversation</div>
                <div className="Home-cta-link-subheading">See how to support a mom through a group chat</div>
              </Link>
            <Link
              className="Home-cta-link Home-cta-link--secondary"
              to="/resources">
              <div className="Home-cta-link-heading">Support the moms in your life</div>
              <div className="Home-cta-link-subheading">Tactical resources and things you can do</div>
            </Link>
          </div>
        </Fact>
      </div>
      <ScrollDown fill={theme.content.color} />
    </Frame>
  )
})

const Fact = ({ body, children, parentOffset }) => (
  <div className="Home-facts-item">
    <SlideIn>
      <div className="Home-facts-item-body">{body}</div>
      {children}
    </SlideIn>
  </div>
)


const Highlight = ({
  children,
  theme
}) => {
  const scrolledStyle = {
    color: theme.content.highlight
  }
  const defaultStyle = {
    color: theme.content.color,
  }
  const [style,set] = useSpring(() => ({
    from: defaultStyle
  }))

  return (
    <Waypoint
      bottomOffset={window.innerHeight/3}
      onEnter={() => {
        set(scrolledStyle)
      }} onLeave={() => {
        set(defaultStyle)
      }}>
      <animated.span
        style={style}
        className="Home-facts-item-body">
        {children}
      </animated.span>
    </Waypoint>
  )
}

const YoutubeEmbed = ({
  placeholder,
  src,
  play,
  setPlay
}) => {
  return <div className="VideoEmbed iframe-wrapper">
    <div className="VideoEmbed-placeholder" onClick={() => {
        setPlay(true)
      }}>
      <img alt="#takecare campaign spot" src={placeholder} />
      <Play />
    </div>
  </div>
}