import React, { useEffect, useState } from "react";
import { Provider, connect } from 'react-redux';
import store from "./redux/store.js";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import "./App.scss";
import Home from "components/Home";
import Chat from "components/Chat";
import themes from "themes.js";
import pages from "pages.json";
import Page from "components/Page";
import Nav from "components/Nav";

const mapStateToProps = state => ({
  theme: themes[state.style.theme]
})
const mapDispatchToProps = null
const App = connect(mapStateToProps,mapDispatchToProps)(({ theme }) => {

  const [style,setStyle] = useSpring(() => (theme.wrapper))
  useEffect(() => {
    setStyle(theme.wrapper)
  },[theme])

  const [play,setPlay] = useState(false);

  useEffect(() => {
    document.body.style.overflow = play ? 'hidden' : ''
  },[play])

  return (
    <Router>
      <Nav />
      <animated.main style={style}>
        <Route exact path="/" render={() => <Home play={play} setPlay={setPlay} />} />
        <Route path="/chat" component={Chat} />
        {pages.data.map(page => (
          <Route
            key={page.slug}
            path={`/${page.slug}`}
            render={() => <Page {...page} />} />
        ))}
        {play && (
          <div className="VideoEmbed-modal" onClick={() => { setPlay(false) }}>
            <button className="VideoEmbed-modal-close" onClick={() => { setPlay(false) }}>
              <div className="VideoEmbed-modal-close-bar" />
              <div className="VideoEmbed-modal-close-bar" />
            </button>
            <div className="iframe-ratio-wrapper">
              <iframe
                title="#takecare"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/7X8II6J-6mU?wmode=opaque&autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen />
            </div>
          </div>
        )}
      </animated.main>
    </Router>
  );
})

export default () => {
  return <Provider store={store}>
    <App />
  </Provider>
}
